import { app } from '@/main'
import { showError } from '@/services/axios'
import { apiURL } from '@/main'
import { defineStore } from 'pinia'
export const useMilkLabelsStore = defineStore('milkLabelsStore', {
  state: () => ({
    milkLabels: [] as any[]
  }),
  actions: {
    async fetchMilkLabels() {
      if (this.milkLabels.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/milkLabels')
          this.milkLabels = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
